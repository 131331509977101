@import './breakpoint.scss';

$imageURL: 'https://motionmy.s3-ap-southeast-1.amazonaws.com/image/';

img {
    transition: filter 0.5s ease;
}

figure {
    background-color: #f5f5f5;
}

img.lazy {
    filter: blur(10px);
    -webkit-filter: blur(10px);
    -ms-filter: blur(10px);
}

body {
    font-family: Metropolis;
}

.hero {
    &.hero-bg-landing {
        position: relative;

        height: 900px;

        @include breakpoint(desktop) {
            height: auto;
        }

        @include breakpoint(mobile) {
            height: 450px;
        }

        .carousel-root,
        .carousel-slider,
        .slider-wrapper,
        ul.slider,
        ul.slider .slide {
            height: 100%;
        }

        .carousel-root {
            @include breakpoint(phablet) {
                height: 90vh;
            }

            ul.slider .slide img {
                height: 100%;
                object-fit: cover;

                @include breakpoint(phablet) {
                    object-position: 30% 0;
                }
            }
        }

        .hero-text-container {
            position: absolute;
            z-index: 1;
            width: 80%;
            left: 50%;
            transform: translate(-50%, 0);
            top: calc(450px + 10vh);

            @include breakpoint(desktop) {
                width: 90%;

                top: unset;
                bottom: 3.75rem;
            }

            @include breakpoint(laptop) {
                width: 95%;
                bottom: 1.68rem;
            }

            @include breakpoint(phablet) {
            }

            h1 {
                color: white;
                font-size: 3.2em;

                @include breakpoint(desktop) {
                    font-size: 2.5em;
                }

                @include breakpoint(tablet) {
                    font-size: 2em;
                }

                @include breakpoint(phablet) {
                    font-size: 1.5em;
                }

                @include breakpoint(mobile) {
                    font-size: 1em;
                }
            }

            .buttons-container {
                display: flex;
                align-items: center;

                a {
                    width: max-content;
                    text-transform: uppercase;
                    padding: 0.5rem 1.68rem;
                    background-color: black;
                    color: white;
                    text-decoration: none;

                    @include breakpoint(phablet) {
                        font-size: 0.8em;
                        padding: 0.6rem 1rem 0.5rem 1rem;
                    }

                    @include breakpoint(mobile) {
                        font-size: 0.65em;
                        padding: 0.5rem;
                    }

                    & + a {
                        margin-left: 1.68rem;

                        @include breakpoint(mobile) {
                            margin-left: 0.5rem;
                        }
                    }
                }
            }
        }
    }

    &.hero-jerseys,
    &.hero-bibs,
    &.section-bg,
    &.hero-our-story,
    &.hero-sizing-guide,
    &.hero-socks,
    &.hero-tshirts {
        width: 90%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include breakpoint(tablet) {
            height: 700px;
        }

        @include breakpoint(phablet) {
            width: 100%;
            height: 600px;
        }

        @include breakpoint(mobile) {
            height: 500px;
        }

        .heading {
            height: 280px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 80%;
            text-align: center;

            @include breakpoint(phablet) {
                height: 240px;
            }

            @include breakpoint(mobile) {
                height: 200px;
            }

            h1 {
                text-transform: uppercase;
                font-weight: bold;
                font-size: 4.5rem;
                margin: 0;
                @include breakpoint(mobile) {
                    font-size: 3em;
                }
            }

            h4 {
                font-weight: 200;
                margin: 15px 0 0 0;
                font-size: 1.3em;
                @include breakpoint(mobile) {
                    font-size: 1em;
                }
            }
        }

        .hero-bg {
            width: 100%;
            height: 60%;
            margin: 0;

            @include breakpoint(mobile) {
                height: 50%;
            }

            picture {
                width: 100%;
                height: 100%;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &.hero-jerseys .hero-bg > img {
        object-position: 0 10%;
    }

    &.hero-faq {
        height: 500px;
        background-image: url('#{$imageURL}background/Asset8.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        text-align: center;
        position: relative;
        z-index: -2;

        @include breakpoint(phablet) {
            height: 400px;
        }

        @include breakpoint(mobile) {
            height: 300px;
        }

        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: -1;
            background-color: rgba(0, 0, 0, 0.2);
        }

        h1 {
            font-weight: bold;
            text-transform: uppercase;
            font-size: 5rem;
            margin: 0;
            z-index: 1;

            @include breakpoint(desktop) {
                font-size: 4em;
            }

            @include breakpoint(laptop) {
                font-size: 3em;
            }

            @include breakpoint(tablet) {
                font-size: 2.5em;
            }

            @include breakpoint(phablet) {
                font-size: 1.8em;
                padding: 0 5%;
            }

            @include breakpoint(mobile) {
                font-size: 2.2em;
                padding: 0 15%;
            }

            @include breakpoint('max-width: 400px') {
                padding: 0 8%;
            }
        }

        h4 {
            letter-spacing: 3px;
            z-index: 1;
            margin: 2rem 0 0;
            font-size: 1.3em;
            font-weight: 300;

            @include breakpoint(desktop) {
                font-size: 1.2em;
            }

            @include breakpoint(laptop) {
                font-size: 1em;
            }

            @include breakpoint(phablet) {
                font-size: 0.9em;
                padding: 0 5%;
            }

            @include breakpoint(mobile) {
                font-size: 1.2em;
                margin: 1rem 0 0;
                letter-spacing: normal;
            }
        }
    }

    &.hero-sizing-guide {
        height: 40vh;
        justify-content: center;
    }
}

@media (min-width: 2000px) {
    .hero.hero-faq {
        background-image: url('#{$imageURL}background/Asset8@2x.jpg');
    }
}

@media (min-width: 4000px) {
    .hero.hero-faq {
        background-image: url('#{$imageURL}background/Asset80@3x.jpg');
    }
}

@font-face {
    font-display: swap;
    font-family: 'Metropolis';
    font-weight: 100;
    font-style: italic;
    src: local('Metropolis'),
        url(../Fonts/Metropolis-ExtraLightItalic.otf) format('opentype');
}

@font-face {
    font-display: swap;
    font-family: 'Metropolis';
    font-weight: 100;
    src: local('Metropolis'), url(../Fonts/Metropolis-ExtraLight.otf) format('opentype');
}

@font-face {
    font-display: swap;
    font-family: 'Metropolis';
    font-weight: 200;
    src: local('Metropolis'), url(../Fonts/Metropolis-Light.otf) format('opentype');
}

@font-face {
    font-display: swap;
    font-family: 'Metropolis';
    font-weight: 200;
    font-style: italic;
    src: local('Metropolis'), url(../Fonts/Metropolis-LightItalic.otf) format('opentype');
}

@font-face {
    font-display: swap;
    font-family: 'Metropolis';
    font-weight: 300;
    src: local('Metropolis'), url(../Fonts/Metropolis-Thin.otf) format('opentype');
}

@font-face {
    font-display: swap;
    font-family: 'Metropolis';
    font-weight: 300;
    font-style: italic;
    src: local('Metropolis'), url(../Fonts/Metropolis-ThinItalic.otf) format('opentype');
}

@font-face {
    font-display: swap;
    font-family: 'Metropolis';
    font-weight: 400;
    src: local('Metropolis'), url(../Fonts/Metropolis-Regular.otf) format('opentype');
}

@font-face {
    font-display: swap;
    font-family: 'Metropolis';
    font-weight: 400;
    font-style: italic;
    src: local('Metropolis'),
        url(../Fonts/Metropolis-RegularItalic.otf) format('opentype');
}

@font-face {
    font-display: swap;
    font-family: 'Metropolis';
    font-weight: 500;
    src: local('Metropolis'), url(../Fonts/Metropolis-Medium.otf) format('opentype');
}

@font-face {
    font-display: swap;
    font-family: 'Metropolis';
    font-weight: 500;
    font-style: italic;
    src: local('Metropolis'), url(../Fonts/Metropolis-MediumItalic.otf) format('opentype');
}

@font-face {
    font-display: swap;
    font-family: 'Metropolis';
    font-weight: 600;
    src: local('Metropolis'), url(../Fonts/Metropolis-SemiBold.otf) format('opentype');
}

@font-face {
    font-display: swap;
    font-family: 'Metropolis';
    font-weight: 600;
    font-style: italic;
    src: local('Metropolis'),
        url(../Fonts/Metropolis-SemiBoldItalic.otf) format('opentype');
}

@font-face {
    font-display: swap;
    font-family: 'Metropolis';
    font-weight: bold;
    src: local('Metropolis'), url(../Fonts/Metropolis-Bold.otf) format('opentype');
}

@font-face {
    font-display: swap;
    font-family: 'Metropolis';
    font-weight: bold;
    font-style: italic;
    src: local('Metropolis'), url(../Fonts/Metropolis-BoldItalic.otf) format('opentype');
}

@font-face {
    font-display: swap;
    font-family: 'Metropolis';
    font-weight: 800;
    src: local('Metropolis'), url(../Fonts/Metropolis-ExtraBold.otf) format('opentype');
}

@font-face {
    font-display: swap;
    font-family: 'Metropolis';
    font-weight: 800;
    font-style: italic;
    src: local('Metropolis'),
        url(../Fonts/Metropolis-ExtraBoldItalic.otf) format('opentype');
}

@font-face {
    font-display: swap;
    font-family: 'Metropolis';
    font-weight: 900;
    src: local('Metropolis'), url(../Fonts/Metropolis-Black.otf) format('opentype');
}

@font-face {
    font-display: swap;
    font-family: 'Metropolis';
    font-weight: 900;
    font-style: italic;
    src: local('Metropolis'), url(../Fonts/Metropolis-BlackItalic.otf) format('opentype');
}
