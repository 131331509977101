@mixin breakpoint($point) {
	@if $point == desktop {
		@media (max-width: 1440px) {
			@content;
		}
	} @else if $point == laptop {
		@media (max-width: 1120px) {
			@content;
		}
	} @else if $point == tablet {
		@media (max-width: 900px) {
			@content;
		}
	} @else if $point == phablet {
		@media (max-width: 768px) {
			@content;
		}
	} @else if $point == mobile {
		@media (max-width: 500px) {
			@content;
		}
	} @else {
		@media ($point) {
			@content;
		}
	}
}
