main.splash {
	width: 100%;
	height: 100vh;
	display: grid;
	place-items: center;
}

svg {
	min-width: 250px;
	max-width: 400px;
	width: 20%;
}

#wheel_1,
#wheel_2 {
	animation: rotate 1s linear infinite;
}
#wheel_1 {
	transform-origin: 235px 470px;
}

#wheel_2 {
	transform-origin: 930px 470px;
}

@keyframes rotate {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
